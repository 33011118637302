import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';
import { theme } from 'common/theme/AppCreative';
import { ResetCSS } from 'common/assets/css/style';
import Input from 'common/components/Input';
import Heading from 'common/components/Heading';
import Select from 'common/components/Select';
import Button from 'common/components/Button';
import Footer from 'containers/AppCreative/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppCreative/appCreative.style';
import loader from '../common/assets/image/loading.gif';
import SEO from '../components/seo';
import request from '../common/helpers/request';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const CardForm = styled.div`
  padding: 30px;
  margin: 5rem auto;
  max-width: 70vh;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 15px 50px rgb(91 132 193 / 10%);
  border: 0px solid;

  @media only screen and (max-width: 600px) {
    margin: 0 auto;
  }
  .select__input {
    height: 45px;
  }

  .select__control {
      border-color: #aaaaaa;
      border-radius: 8px;
  }

  .select__single-value {
      top: 70%
  }

  input,
  textarea {
    font-size: 16px;
    min-height: 55px;
    padding: 0 25px 0 12px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #aaaaaa;
    margin: 10px 0px;
    color: ${themeGet('colors.textColor')};
    font-family: 'DM Sans', sans-serif;
    ::placeholder {
      color: ${rgba('#343D48', 0.8)};
      opacity: 1; /* Firefox */
    }
    &:focus {
      border-color: ${themeGet('colors.textColor')};
    }

    @media only screen and (max-width: 1280px) {
      min-height: 50px;
    }
  }

  button {
    display: flex;
    border-radius: 8px;
    font-size: 16px;
    min-height: 55px;
  }

  textarea  {
    width: 100%;
    resize: none;
  }
`;

const Loading = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    heigth: 50px;
  }
`;
const PlaceOption = styled.div`
	padding: 10px;
    border-bottom: 1px solid #cad1d7;
`;

const PlaceOptionContainer = styled.div`
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    border-radius: 5px;
    transition: box-shadow .15s ease;
`;

const PlacesLoading = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    
    span {
        margin-right: 5px;
    }
`

const LocationDataItem = styled.p`
    font-size: 14px;
`

const ThankYouMessage = () => (
  <div>
    <Heading as={'h1'} content="Thanks for contacting us" />
    <p>
      We will contact you in a few hours and solve all your questions. If you want more information please contact this number:{' '}
      <a href="tel:+190590 90590">+190590 90590</a>
    </p>
  </div>
);

function validateEmail(email) {
  //Validates the email address
  var emailRegex =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailRegex.test(email);
}

function validatePhone(phone) {
  //Validates the phone number
  var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  return phoneRegex.test(phone);
}

export default function CreateYourOnlineStore() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [referral, setReferral] = useState('');
  const [comments, setComments] = useState('');
  const [error, setError] = useState('');
  const [leadSaved, setLeadSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const createLead = async () => {
    const lead = {
      name,
      referral,
      phone,
      email,
      comments,
    };
    try {
      if (
        Object.keys(lead).filter((k) => k !== 'comments' && lead[k] === '')
          .length
      ) {
        setError('All fields are required');
        return;
      }

      if (!validateEmail(email)) {
        setError('Email must be a valid email');
        return;
      }

      if (!validatePhone(phone)) {
        setError('Phone must be a valid phone');
        return;
      }

      setError('');
      setLoading(true);
      await request.post('/users/contact-lead', {
        ...lead,
        countryCode: '1'
      });
      setLoading(false);
      setLeadSaved(true);
    } catch (e) {
      setError('Something was wrong, please try again later');
      setLoading(false);
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Create your online store" />

        <ResetCSS />
        <GlobalStyle />
        {loading && (
          <Loading>
            <div className="container-loader">
              <img alt="loading-img" src={loader} />
              <p>Creating your store...</p>
            </div>
          </Loading>
        )}
        <AppWrapper>
          <ContentWrapper>
            <CardForm>
              {leadSaved ? (
                <ThankYouMessage />
              ) : (
                <>
                  <Heading content="Contact us" as="h1" />
                  <p>
                    Leave us a short message and we will contact you in a few hours
                  </p>
                  <Input
                    value={name}
                    onChange={setName}
                    inputType="text"
                    placeholder="Your name"
                    name="name"
                  />
                  <Input
                    value={phone}
                    onChange={setPhone}
                    inputType="phone"
                    placeholder="Phone"
                    name="phone"
                  />
                  <Input
                    value={email}
                    onChange={setEmail}
                    inputType="email"
                    placeholder="Your email"
                    name="email"
                  />
                  <Select
                    onChange={option => setReferral(option.value)}
                    placeholder="How did you hear about us?"
                    options={['Facebook', 'Instagram', 'Google', 'A friend', 'Saw your ads', 'Other'].map(o => ({ value: o, label: o }))}
                  />
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    rows={5}
                    placeholder="Any comments you got"
                    name="comments"
                  />

                  {error !== '' && <p>{error}</p>}

                  <Button
                    title="Contact us!"
                    onClick={createLead}
                  />
                </>
              )}
            </CardForm>
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
